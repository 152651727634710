import { useEffect } from "react";
import * as R from "ramda";
import { object } from "prop-types";
import { useStaticQuery, graphql, navigate } from "gatsby";
import cx from "classnames";
import {
  extractRichText,
  extractLink,
} from "../../common/contentful-extract.jsx";

import "aos/dist/aos.css";
import AOS from "aos";

import "./help-category.scss";
import "../../scss/global.scss";

import BackgroundImage from "gatsby-background-image";
import ContentfulLink, {
  getTarget,
} from "../ContentfulLink/ContentfulLink.jsx";
import Container100 from "../Container/Container100.jsx";
import Container5050 from "../Container/Container5050.jsx";
import Page from "../Page/Page.jsx";
import Section from "../Section/Section.jsx";

const propTypes = {};

const HelpPage = (props) => {
  const contentfulData = useStaticQuery(query);

  const { pageTitle, pageSubtitle, categories } = R.compose(
    R.evolve({
      pageTitle: extractRichText,
      pageSubtitle: extractRichText,
      categories: R.compose(
        R.filter((category) => category.items.length > 0), // hide empty sections
        R.map(
          R.evolve({
            subtitle: extractRichText,
            items: R.compose(
              R.map(
                R.evolve({
                  content: extractRichText,
                  link: extractLink,
                }),
              ),
              R.defaultTo([]),
            ),
            backgroundImage: R.prop("fluid"),
            textBackgroundImage: R.prop("fluid"),
          }),
        ),
        R.defaultTo([]),
      ),
    }),
    R.prop("contentfulHelpPage"),
  )(contentfulData);

  // use a BackgroundImage if we have a "fluid" gatsby-image, or just use a div
  const TextWrapper = (props) => {
    return !R.isNil(props.fluid) ? (
      <div>
        <BackgroundImage
          {...props}
          style={{
            backgroundSize: "contain",
            backgroundOrigin: "content-box",
          }}
        />
      </div>
    ) : (
      <div {...props} />
    );
  };
  TextWrapper.propTypes = {
    fluid: object, // gatsby image
  };

  useEffect(() => {
    // https://github.com/michalsnik/aos
    // initialize animation on scroll when the page loads
    AOS.init({
      offset: 100,
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <Page title="Help">
      <Section aos={true} title={pageTitle} subtitle={pageSubtitle}>
        {categories.map((category, i) => {
          const {
            title,
            subtitle,
            items,
            backgroundImage,
            textBackgroundImage,
          } = category;

          return (
            <Container100 key={title} className="help-category">
              <Container5050
                data-aos="fade-up"
                className={cx("help-category__header", {
                  "container--50-50--reverse": i % 2 === 0,
                })}
                right={
                  <BackgroundImage
                    fluid={backgroundImage}
                    className="help-category__background-image"
                  />
                }
                left={
                  <TextWrapper
                    className="help-category__header-text fancy-bold"
                    fluid={textBackgroundImage}
                  >
                    <div className="help-category__title">
                      <strong>{title}</strong>
                    </div>
                    <div className="help-category__subtitle">{subtitle}</div>
                  </TextWrapper>
                }
              />
              <div className="help-category__items">
                {items.map((item, i) => {
                  const { title, content, link } = item;

                  return (
                    <div
                      className="help-category__item"
                      key={`${title}-${i}`}
                      data-aos="fade-up"
                      data-aos-delay={(i % 3) * 250}
                    >
                      {/* eslint-note: keyboard navigation is handled by the ContentfulLink
                        this click handler is so the whole "card" is clickable */}
                      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
                      <div
                        className={cx("help-category__item-content", {
                          "help-category__item-content--clickable":
                            !R.isNil(link),
                        })}
                        onClick={() => {
                          if (!R.isNil(link)) {
                            // for external and media links, open a in a new window
                            if (getTarget(link.type) === "_blank") {
                              window.open(link.to, "_blank");
                            }
                            // for internal/page links, navigate regularly
                            else {
                              navigate(link.to);
                            }
                          }
                        }}
                      >
                        <h4 className="help-category__item-title">{title}</h4>
                        <div>{content}</div>
                      </div>

                      {link && (
                        <ContentfulLink
                          {...link}
                          className="help-category__btn"
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </Container100>
          );
        })}
      </Section>
    </Page>
  );
};

HelpPage.propTypes = propTypes;
export default HelpPage;

const query = graphql`
  query HelpPage {
    contentfulHelpPage {
      pageTitle {
        raw
      }
      pageSubtitle {
        raw
      }
      categories {
        title
        subtitle {
          raw
        }
        backgroundImage {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
            srcWebp
            srcSetWebp
          }
        }
        textBackgroundImage {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
            srcWebp
            srcSetWebp
          }
        }
        items {
          title
          content {
            raw
          }
          link {
            ...Link
          }
        }
      }
    }
  }
`;
